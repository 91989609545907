import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonSearchbar,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { AlertButton, SearchbarCustomEvent } from '@ionic/angular';
import { assertIsDefined, cleanAddressText } from '../utils';
import { YaMapService } from '../ya-map.service';

type YaSuggest = {
  uri: string;
  title: {
    text: string;
  };
  subtitle?: {
    text: string;
  };
};

export type SearchAddressSelectEvent = {
  fullAddress: string;
  coords: number[]; // [Latitude, Longitude]
};

@Component({
  selector: 'app-search-address-with-map-modal',
  standalone: true,
  imports: [
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonSearchbar,
    IonTitle,
    IonToolbar,
    IonText,
    IonAlert,
  ],
  templateUrl: './search-address-with-map-modal.component.html',
  styleUrl: './search-address-with-map-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchAddressWithMapModalComponent implements AfterViewInit, OnDestroy {
  @Output() appClose = new EventEmitter<void>();
  @Output() addressSelect = new EventEmitter<SearchAddressSelectEvent>();
  @Input() initAddress?: SearchAddressSelectEvent;

  @ViewChild('yaMapContainer') yaMapContainer?: ElementRef<HTMLDivElement>;

  protected addressText = '';
  protected yaSuggests: YaSuggest[] = [];
  protected geoSynced = false;
  protected addressInputValue = '';

  private yaMap: any;

  constructor(
    private cdRef: ChangeDetectorRef,
    private yaMapSrv: YaMapService,
  ) {}

  async ngAfterViewInit() {
    await this.setupYaMap();
    if (this.initAddress) {
      this.addressInputValue = this.initAddress.fullAddress;
      this.cdRef.markForCheck();
    }
  }

  ngOnDestroy() {
    if (this.yaMap) {
      this.yaMap.destroy();
    }
  }

  onAddressSearchbarInput(ev: Event) {
    ev.preventDefault();
    this.geoSynced = false;
  }

  async onAddressSearchbarIonInput(ev: SearchbarCustomEvent) {
    ev.preventDefault();
    const searchValue = ev.detail.value;
    if (searchValue) {
      const res = await this.yaMapSrv.geoSuggestByText(searchValue);
      this.yaSuggests = res.results;
    } else {
      this.yaSuggests = [];
    }
    this.cdRef.detectChanges();
  }

  async onSuggestClick(ev: Event, sugg: YaSuggest) {
    ev.preventDefault();
    this.yaSuggests = [];
    const res = await this.yaMapSrv.geoCodeByUri(sugg.uri);
    this.syncGeoResult(res);
    this.cdRef.detectChanges();
  }

  onToolbarCloseBtnClick(ev: Event) {
    ev.preventDefault();
    if (this.geoSynced) {
      this.isConfirmationAlert = true;
    } else {
      this.appClose.emit();
    }
  }

  onSaveAddressBtnClick(ev: Event) {
    ev.preventDefault();
    this.addressSelect.emit({
      fullAddress: this.addressText,
      coords: this.getYaMapCenterCoords(),
    });
  }

  private async setupYaMap() {
    const ymaps = await this.yaMapSrv.ymaps;
    const div = this.yaMapContainer?.nativeElement;
    assertIsDefined(div);
    let initCoords = [55.76, 37.64];
    if (this.initAddress) {
      initCoords = this.initAddress.coords;
    }
    this.yaMap = new ymaps.Map(div, {
      center: initCoords,
      controls: ['zoomControl', 'geolocationControl'],
      zoom: 10,
    });
    const geolocCtr = this.yaMap.controls.get('geolocationControl');
    geolocCtr.events.add('click', (ev: any) => {
      this.toggleYaMapActionHandlers(false);
    });
    geolocCtr.events.add('locationchange', (ev: any) => {
      setTimeout(() => {
        const pos = ev.get('position');
        this.yaMap.setCenter(pos, 15);
        this.onYaMapActionEnd();
        this.toggleYaMapActionHandlers(true);
      }, 333);
    });
    this.toggleYaMapActionHandlers(true);
  }

  private toggleYaMapActionHandlers(onOff: boolean) {
    if (onOff) {
      this.yaMap.events.add('actionbegin', this.onYaMapActionBegin);
      this.yaMap.events.add('actionend', this.onYaMapActionEnd);
    } else {
      this.yaMap.events.remove('actionbegin', this.onYaMapActionBegin);
      this.yaMap.events.remove('actionend', this.onYaMapActionEnd);
    }
  }

  private onYaMapActionBegin = () => {
    this.addressText = '';
    this.addressInputValue = '';
    this.yaSuggests = [];
    this.geoSynced = false;
    this.cdRef.detectChanges();
  };

  private onYaMapActionEnd = async () => {
    const coords = this.yaMap.getCenter();
    const res = await this.yaMapSrv.geoCodeByCoords(coords);
    this.syncGeoResult(res);
    this.cdRef.detectChanges();
  };
  alertButtons: AlertButton[] = [
    {
      text: 'Отмена',
      role: 'destructive',
      handler: () => {},
    },
    {
      text: 'OK',
      handler: () => {
        this.appClose.emit();
      },
    },
  ];
  isConfirmationAlert = false;

  private getYaMapCenterCoords() {
    assertIsDefined(this.yaMap);
    const currentState = this.yaMap.action.getCurrentState();
    return this.yaMap.options
      .get('projection')
      .fromGlobalPixels(currentState.globalPixelCenter, currentState.zoom);
  }

  private syncGeoResult(geoResp: any) {
    const geoObject = geoResp.response.GeoObjectCollection.featureMember[0].GeoObject;
    if (geoObject) {
      this.addressText = geoObject.metaDataProperty.GeocoderMetaData.text;
      this.addressInputValue = cleanAddressText(this.addressText);
      const lonLat = geoObject.Point.pos.split(' ').map((x: string) => parseFloat(x));
      const latLon = lonLat.reverse();
      this.toggleYaMapActionHandlers(false);
      this.yaMap.setCenter(latLon);
      this.geoSynced = true;
      this.toggleYaMapActionHandlers(true);
    }
  }

  onConfirmationAlertDismiss(ev: Event) {
    ev.preventDefault();
    this.isConfirmationAlert = false;
  }
}
