import { Injectable } from '@angular/core';

const YAMAPS_GEOCODER_KEY = '95275d77-20a8-4d12-a9df-0ccff7271600';
const YAMAPS_SUGGEST_KEY = '955c796d-5534-4cb6-ba1c-f7b21a8ab6b2';

@Injectable({
  providedIn: 'root',
})
export class YaMapService {
  readonly ymaps: Promise<any>; // there is no typing for YaMaps 2.x
  private yaCache = new Map<string, any>();

  constructor() {
    this.ymaps = new Promise((res, rej) => {
      let url = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU';
      url += `&apikey=${YAMAPS_GEOCODER_KEY}`;
      url += `&suggest_apikey=${YAMAPS_SUGGEST_KEY}`;
      const script = document.createElement('script');
      // script.type = "text/javascript";
      script.src = url;
      script.onload = () => {
        const { ymaps } = window as any;
        ymaps.ready(() => res(ymaps));
      };
      script.onerror = rej;
      document.head.appendChild(script);
    });
  }

  async geoSuggestByText(text: string) {
    let url = 'https://suggest-maps.yandex.ru/v1/suggest';
    url += `?apikey=${YAMAPS_SUGGEST_KEY}`;
    url += `&text=${text}`;
    url += '&attrs=uri';
    return this.cachedFetch(url);
  }

  async geoCodeByUri(uri: string) {
    let url = 'https://geocode-maps.yandex.ru/1.x';
    url += `?apikey=${YAMAPS_GEOCODER_KEY}`;
    url += `&uri=${uri}`;
    url += `&format=json`;
    return this.cachedFetch(url);
  }

  async geoCodeByCoords(latLon: number[]) {
    let url = 'https://geocode-maps.yandex.ru/1.x';
    url += `?apikey=${YAMAPS_GEOCODER_KEY}`;
    url += `&geocode=${latLon.join(',')}`;
    url += `&sco=latlong`;
    url += `&format=json`;
    return this.cachedFetch(url);
  }

  private async cachedFetch(url: string) {
    if (!this.yaCache.has(url)) {
      const res = await fetch(url, { credentials: 'omit' });
      const data = await res.json();
      this.yaCache.set(url, data);
    }
    return this.yaCache.get(url);
  }
}
